import React, { FC } from 'react';
import { Link } from 'gatsby';

import NFButton from 'components/common/NFButton';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { ISearchResultListComponentProps } from './model';

import 'components/SearchResultList/SearchResultList.scss';

const SearchResultList: FC<ISearchResultListComponentProps> = ({ listItem, btn }) => {
  return (
    <div className="search-result-list-holder">
      <ul className="search-result-list">
        {listItem.map(({ link, title, description }, id) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={`${link}-${id}`}>
            <strong className="search-result-list__title-link">
              <Link to={link}>
                <DangerouslySetInnerHtml
                  html={title}
                  className="search-result-list__title"
                />
              </Link>
            </strong>
            <DangerouslySetInnerHtml
              html={description}
              className="search-result-list__text"
            />
          </li>
        ))}
      </ul>
      {btn ? <NFButton {...btn} /> : null}
    </div>
  );
};

export default SearchResultList;