import React, { FC, useEffect, useState } from 'react';
import { graphql, PageProps } from 'gatsby';
import algoliasearch, { SearchClient, SearchIndex } from 'algoliasearch/lite';
import classnames from 'classnames';
import uniqBy from 'lodash/uniqBy';
import { Col, Row, Container } from 'react-bootstrap';

import Layout from 'components/Layout';
import SearchResultList from 'components/SearchResultList';
import CustomSearch from 'components/CustomSearch';
import FeaturedArticles from 'components/FeaturedArticles';
import UsefulLinks from 'components/UsefulLinks';
import ExtendedFeaturedArticles from 'components/ExtendedFeaturedArticles';
import { parseNFButton } from 'components/common/NFButton/parsers';
import { parseExtendedFeaturedArticles } from 'components/ExtendedFeaturedArticles/parsers';

import useScreenRecognition from 'hooks/useScreenRecognition';
import { getQueryParamByName, isBrowser } from 'utils/browser';
import { gtmService } from 'services/gtmService';

import { AlgoliaSearchResultInterface } from './models';

import './search-result.scss';

const client: SearchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID as string,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY as string
);
const searchSampleIndex: SearchIndex = client.initIndex(
  `${process.env.GATSBY_ALGOLIA_INDEX_PREFIX}-search` as string
);

interface IPropsSearchResultPage extends PageProps {
  data: SearchResultTypes.ISearchResults;
}

const SearchResultPage: FC<IPropsSearchResultPage> = ({
  data: {
    page,
    featuredArticlesByTag,
    carouselControls,
    header,
    footer,
    siteSettings,
    covidBanner,
    newsletterPopupDefault,
    newsletterPopupDiscount,
    newsletterPopupFreeDelivery,
    disclaimerPopup,
    searchPath,
    brandSettings,
    legalPopup,
    pageLangs: { nodes: langs },
    extendedFeaturedArticlesPainAdviceByLink,
    extendedFeaturedArticlesPainAdviceByTag,
    extendedFeaturedArticlesSymptomsByLink,
    extendedFeaturedArticlesSymptomsByTag,
  },
}) => {
  const { isMobile } = useScreenRecognition();

  const searchQuery: string = (isBrowser() && getQueryParamByName('query')) || '';
  const [searchResults, setSearchResults] = useState<{
    results: AlgoliaSearchResultInterface[];
    query: string;
  }>({ results: [], query: '' });
  const [resLimit, setResLimit] = useState<number>(parseInt(page.pageItemsCount, 10));
  const [isSearchComplete, setSearchComplete] = useState<boolean>(false);

  const loadMore = (e) => {
    setResLimit(resLimit + parseInt(page.pageItemsCount, 10));
    e.currentTarget.blur();
  };

  useEffect(() => {
    let timeoutId;

    searchSampleIndex
      .search<AlgoliaSearchResultInterface>(decodeURI(searchQuery), {
        typoTolerance: false,
        filters: `lang:"${siteSettings.lang}"`,
      })
      .then(({ hits }) => {
        setSearchResults({
          results: uniqBy(hits, 'title'),
          query: hits.length ? searchQuery : '',
        });

        timeoutId = gtmService.emitSearchResultsView(searchQuery);

        setSearchComplete(true);
      })
      .catch(() => {
        setSearchComplete(true);
      });

    return () => {
      timeoutId && clearTimeout(timeoutId);
    };
  }, [searchQuery]);

  const featuredArticlesData = page?.featuredArticles?.[0]?.properties;
  const hasSearchResults = !!searchResults.results.length;

  const parsedExtentedRelatedArticles = page?.extentedRelatedArticles?.[0]
    ? parseExtendedFeaturedArticles({
        extendedFeaturedArticles: page?.extentedRelatedArticles?.[0]?.properties,
        articlesByLink: [
          ...extendedFeaturedArticlesPainAdviceByLink?.nodes,
          ...extendedFeaturedArticlesSymptomsByLink?.nodes,
        ],
        articlesByTag: [
          ...extendedFeaturedArticlesPainAdviceByTag?.nodes,
          ...extendedFeaturedArticlesSymptomsByTag?.nodes,
        ],
        carouselControls,
      })
    : undefined;

  return isSearchComplete ? (
    <Layout
      seo={{
        seoMetaDescription: page.seoMetaDescription,
        seoMetaKeywords: page.seoMetaKeywords,
        seoMetaTitle: page.seoMetaTitle,
        seoExternalHreflangs: page.seoExternalHreflangs,
      }}
      header={header}
      footer={footer}
      siteSettings={siteSettings}
      covidBanner={covidBanner}
      newsletterPopupDefault={newsletterPopupDefault}
      newsletterPopupDiscount={newsletterPopupDiscount}
      newsletterPopupFreeDelivery={newsletterPopupFreeDelivery}
      disclaimerPopup={disclaimerPopup}
      searchPath={searchPath}
      brandSettings={brandSettings}
      legalPopup={legalPopup}
      langSettings={{
        currentLang: page.lang,
        langs,
      }}
    >
      <section
        className={classnames('search-result-page', {
          'search-result-found': hasSearchResults,
          'search-result-not-found-page': !hasSearchResults,
        })}
      >
        <Container fluid>
          <Row>
            <Col md={{ span: 10, offset: 1 }}>
              <CustomSearch
                mainTitle={page.mainTitle}
                title={hasSearchResults ? page.title : page.noResultsTitle}
                subtitle={hasSearchResults ? '' : page.noResultsText}
                keyWord={searchQuery}
                hasSearchResults={hasSearchResults}
                resultsCount={searchResults.results.length}
                placeholder={hasSearchResults ? page.inputPlaceholder : ''}
                searchPath={searchPath?.nodes?.[0]?.link}
              />
              {hasSearchResults ? null : (
                <UsefulLinks title={page.usefulLinksText} listItem={page.usefulLinks} />
              )}
              {hasSearchResults ? (
                <SearchResultList
                  listItem={[...searchResults.results].slice(0, resLimit)}
                  btn={
                    resLimit < searchResults.results.length
                      ? {
                          onClick: loadMore,
                          btnText: isMobile ? page.loadMoreMobileText : page.loadMoreText,
                          type: 'button',
                        }
                      : undefined
                  }
                />
              ) : null}
            </Col>
          </Row>
        </Container>
        {featuredArticlesData ? (
          <FeaturedArticles
            bgColor={featuredArticlesData.featuredArticlesBgColor}
            title={featuredArticlesData.featuredArticlesSectionTitle}
            subtitle={featuredArticlesData.featuredArticlesSectionSubtitle || ''}
            btn={
              featuredArticlesData.featuredArticlesCTAButtonData?.length
                ? {
                    ...parseNFButton(
                      featuredArticlesData.featuredArticlesCTAButtonData[0].properties
                    ),
                  }
                : undefined
            }
            articles={featuredArticlesData.featuredArticlesData}
            mode={featuredArticlesData.featuredArticlesSelectedArticleMode}
            articlesByTag={featuredArticlesByTag.nodes}
            carouselControls={carouselControls}
          />
        ) : null}
        {parsedExtentedRelatedArticles ? (
          <ExtendedFeaturedArticles {...parsedExtentedRelatedArticles} />
        ) : null}
      </section>
    </Layout>
  ) : null;
};

export const query = graphql`
  query(
    $lang: String!
    $articleTagId: Int
    $link: String = ""
    $pageIdRegex: String
    $extendedFeaturedArticlesByTagIds: [Int]
    $extendedFeaturedArticlesByLinks: [String]
  ) {
    page: searchResults(link: { eq: $link }, lang: { eq: $lang }) {
      ...FragmentSeo
      featuredArticles {
        properties {
          ...FragmentFeaturedArticles
        }
      }
      extentedRelatedArticles {
        properties {
          ...FragmentExtendedFeaturedArticles
        }
      }
      link
      lang
      noResultsText
      noResultsTitle
      pageItemsCount
      mainTitle
      title
      descriptionCharsLimit
      loadMoreText
      loadMoreMobileText
      usefulLinksText
      inputPlaceholder
      usefulLinks {
        name
        url
      }
    }
    featuredArticlesByTag: allUmbracoArticles(
      filter: { tags: { elemMatch: { id: { eq: $articleTagId } } }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentFeaturedArticleData
      }
    }
    carouselControls: siteSettings(lang: { eq: $lang }) {
      ariaLabelNext
      ariaLabelPrev
    }
    pageLangs: allSearchResults(filter: { umbracoId: { regex: $pageIdRegex } }) {
      nodes {
        link
        lang
      }
    }
    ...FragmentExtendedFeaturedArticlesData
    ...FragmentCommonCompositions
  }
`;

export default SearchResultPage;
